import React from "react";
import "../assets/css/digital4all.css";
import digital4alllogo from "../assets/d4a-img/digital4all-logo.png";
import icondiscover from "../assets/d4a-img/icon-discover.png";
import icondive from "../assets/d4a-img/icon-dive.png";
import iconboost from "../assets/d4a-img/icon-boost.png";
import { useEffect } from "react";

const Digital4all = () => {
   useEffect(() => {
      document.body.classList.add("digital4allbody");
      return () => {
         document.body.classList.remove("digital4allbody");
      };
   }, []);
   return (
      <div className="parent-container mx-auto h-screen max-w-xl 2xl:max-w-lg">
         <div className="header"></div>
         <div className="content m-auto mt-2 w-full">
            <div className="flex h-full flex-col items-center text-center">
               <span className="px-2">
                  <img src={digital4alllogo} alt="DIGITAL4ALL" />
               </span>
               <h1 className="get-inspired my-5 px-2 text-lg leading-[19.8px]">
                  Get inspired and immerse yourself
                  <br />
                  in an exciting event to unleash <br /> the inner creator in
                  all of us!
               </h1>

               <div className="event-box mt-4 bg-[length:100%] bg-top pt-5 max-md:bg-contain max-sm:bg-[length:130%] lg:bg-[length:90%]">
                  <div className="discover-box m-auto mb-[0.8rem] flex w-64 flex-row items-center rounded-md">
                     <div className="discover flex-auto content-center items-center rounded-l-lg">
                        <img
                           src={icondiscover}
                           alt="Discover industry"
                           className="ml-2"
                        />
                     </div>
                     <div className="event-text grow justify-center pl-1 text-left text-lg font-semibold leading-5">
                        Discover industry <br />
                        disruptors' insights
                     </div>
                  </div>

                  <div className="discover-box m-auto mb-[0.8rem] flex w-64 flex-row items-center rounded-md">
                     <div className="discover flex-auto content-center items-center rounded-l-lg">
                        <img
                           src={icondive}
                           alt="Dive into digital"
                           className="ml-2"
                        />
                     </div>
                     <div className="event-text grow justify-center pl-1 text-left text-lg font-semibold leading-5">
                        Dive into digital
                        <br />
                        campaign & creation
                     </div>
                  </div>

                  <div className="discover-box m-auto mb-[0.8rem] flex w-64 flex-row items-center rounded-md">
                     <div className="discover flex-auto content-center items-center rounded-l-lg">
                        <img
                           src={iconboost}
                           alt="Boost creativity"
                           className="ml-2"
                        />
                     </div>
                     <div className="event-text grow justify-center pl-1 text-left text-lg font-semibold leading-5">
                        Boost creativity, <br />
                        cultivate habits, and <br />
                        conquer challenges
                     </div>
                  </div>

                  <div className="date-container mt-10 h-12 content-center bg-[#fcc456] bg-opacity-30 px-4 py-2.5">
                     <p className="text-xs font-extrabold leading-4 md:text-base">
                        <span className="date">May 22, 2024 (1pm - 5pm) |</span>
                        <span className="date"> B1, The Globe Tower, BGC</span>
                     </p>
                  </div>
                  <p className="my-5 text-base font-medium text-white">
                     See you there, Ka-Globe Creators!
                  </p>

                  <a
                     href="https://docs.google.com/forms/d/e/1FAIpQLSeer503XBfoLIlLg9u0Z9p4KR3upJuYbh-K5tf9DXVvsEuZcw/viewform"
                     //  style="width: -webkit-fill-available"
                     target="_self"
                  >
                     <button className="my-2 h-12 w-11/12 rounded bg-[#2274E5] px-4 py-2 font-semibold text-white hover:bg-blue-700 md:w-3/5">
                        Register Now!
                     </button>
                  </a>
               </div>
            </div>
         </div>
         <div className="footer"></div>
      </div>
   );
};

export default Digital4all;
